@import "./variables"; 
@mixin position($position , $top:auto , $right:auto , $bottom:auto , $left:auto , $z:1) {
    position: $position;
    top:$top; right:$right;
    bottom: $bottom; left:$left ;
    z-index: $z;  
}

@mixin flex($fs:row , $jc:center ,$al:center , $ac:center) {
    display: flex;
    flex-direction: $fs;
    justify-content: $jc;
    align-items: $al;
    align-content: $ac;
}

%section-padding{
    padding: 10rem 0;
}

%section-title{
    font-size: 4rem;
    text-align: center;
    font-weight: 600;
    margin: 0 auto 5rem;
    display: inline-block;
    position: relative;
    font-family: 'Montserrat Alternates', sans-serif;
    &::after{
        content: "";
        position: absolute;
        bottom: 0; left: 0;
        background-color: $second-color;
        width: 100%;
        height: 3px;
    }
}

%section-subtitle{
    font-size: 2rem;
    text-align: center;
    font-weight: 400;
    margin-bottom: 3rem;
    font-family: 'Montserrat Alternates', sans-serif;
}