@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat+Alternates:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
* {
  font-family: 'Poppins', sans-serif;
  margin: 0; padding: 0;
  box-sizing:border-box ;
}
html{
  font-size: 10px;
  scroll-padding: 6rem;
}

body{
  scroll-padding: 5rem;
}

@media (max-width:992px) {
  html{
    font-size: 9px;
  }
}

@media (max-width:768px) {
  
}

@media (max-width:450px) {
  html{
    font-size: 8px;
  }
}


// @media (max-width:992px) {

// }

// @media (max-width:768px) {
  
// }

// @media (max-width:450px) {
 
// }