@import "../../styles/mixins";
@import "../../styles/variables";

// nav{
//     background-color: #ededed;
//     font-size: 1.6rem;
//     box-shadow: $box-shadow;
//     padding: 1.5rem;
//     border-bottom: 2px solid $second-color;
//     position: sticky;
//     top: 0; z-index: 50;
//     @include flex(row,space-around);
//     h3{
//         margin: 0;
//         font-size: 2.5rem;
//         span{
//             color: $second-color;
//         }
//     }
//     ul{
//         @include flex();
//         list-style-type: none;
//         padding: 0; margin: 0;
//         li{
//             margin: 0 3rem;
//             font-weight: 600;
//             a{
//                 color: $black;
//                 text-decoration: none;
//                 text-transform: capitalize;
//                 &:hover{
//                     color: $second-color;
//                 }
//             }
//         }
//     }
// }

nav{
    background-color: #ededed;
    font-size: 1.6rem;
    box-shadow: $box-shadow;
    padding: 1.5rem;
    border-bottom: 2px solid $second-color;
    position: sticky !important;
    top: 0 !important; z-index: 4;
   // @include flex(row,space-around);
        h3{
        margin: 0;
        font-size: 2.5rem;
        span{
            color: $second-color;
        }
    }
    ul{
        @include flex();
        list-style-type: none;
        padding: 0; margin: 0;
        li{
            margin: 0 3rem;
            font-weight: 600;
            a{
                color: $black !important;
                text-decoration: none;
                text-transform: capitalize;
                &:hover{
                    color: $second-color !important;
                }
            }
        }
    }
    .container-fluid{
        justify-content: space-around !important;
    }
    .navbar-collapse{
        flex-grow: 0;
    }
}