@import "../../styles/mixins";
@import "../../styles/variables";

header {
    background-attachment: fixed;
    background-image: url("../../assests/download.jpg");
	height: 100vh;
    position: relative;
    @include flex();
    .header__content{
        text-align: center;
        position: relative;
        z-index: 5;
        h1{
            font-family: 'Montserrat Alternates', sans-serif;
            color: $white;
            font-size: 5rem;
            margin-bottom: 2rem;
            line-height: 7.5rem;
            span{
                color: $second-color;
                font-weight: 600;
            }
        }
        a{
            font-size: 2.5rem;
            color: $white;
            border: 3px solid $white;
            border-radius: 5px;
            text-decoration: none;
            padding: 0.5rem 1rem;
            &:hover{
                cursor: pointer;
                border: 3px solid $second-color;
                color: $second-color;
            }
        }
    }
    .intro{
        background-color: $black;
        @include position(fixed , 0 , 0 ,0 , 0 , 5);
        @include flex();
        .intro__content h1{
            color:$white;
            font-size: 5rem;
            font-family: 'Montserrat Alternates', sans-serif;
            .work{
                font-family: 'Montserrat Alternates', sans-serif;
                color: $second-color;
                font-weight: 700;
                font-size: 5.5rem;
            }
        }
        .hide{
            background-color: $black;
            overflow-y: hidden;
            span{
                transform: translateY(100%);
                display: inline-block;
            }
        }
    }
    .trans{
        background-color: $second-color;
        @include position(fixed , 0 , 0 ,0 , 0 , 6);
        transform: translateY(100%);
    }
}