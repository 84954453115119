@import "../../styles/mixins";
@import "../../styles/variables";

.projects{
    @extend %section-padding;
    overflow: hidden;
    text-align: center;
    font-size: 1.6rem;
    h2{
        @extend %section-title;
        position: relative;
        padding-bottom: 1rem;
    }
    .projects-types{
        @include flex(row , space-evenly);
        flex-wrap: wrap;
        width: 80%;
        margin: 0 auto 10rem;
        h4{
            border: 2px solid $second-color;
            font-size: 2rem;
            padding: 0.5rem 2rem;
            border-radius: 20px;
            margin-bottom: 1rem;
            &:hover{
                border: 2px solid $second-color;
                background-color: $second-color;
                color: $white;
                cursor: pointer;
            }
            &.active{
                border: 2px solid $second-color;
            background-color: $second-color;
            color: $white;
            }
        }
    }
    .project{
        border-radius: 10px;
        box-shadow: $box-shadow;
        border: 1px solid #d4d4d4;
        width: 95%;
        margin: 0 auto 2rem;
        border-radius: 25px 5px 25px 5px;
        img{
            width: 100%;
            border-radius: 25px 5px 5px 5px;
            border: 1px solid #a5a5a5;
        }
        h5{
            font-size: 2rem;
        }
        .project__content{
            @include flex(row , space-between);
            padding: 0.5rem 1rem;
        }
        .project__badge{
            background-color:#c7c7c7;
            border-radius: 25px;
            font-size: 1.2rem;
            padding: 0.25rem 1rem;
        }
        .project__img{
            position: relative;
            overflow: hidden;
            transition: left 5s linear;
            &:hover .links{
                left: 0
            }
        }
        .links{
            @include flex(column , space-evenly);
            @include position(absolute , 0 , -100% , auto , auto);
            width: 100%;
            height: 100%;
            background-color: #ffffff90;
            a{
                font-size: 2rem;
                border-radius: 5px;
                padding: 0.25rem 1rem;
                margin: 0 1rem;
                text-decoration: none;
                color: $white;
                &.preview{
                    border: 1px solid #008CBA;
                    color: $white;
                    background-color: #008CBA;
                }
                &.code{
                    border: 1px solid #4CAF50;
                    color: $white;
                    background-color: #4CAF50;
                }
                &:hover{
                    transform: scale(1.1);
                }
            }
        }
    }
}